import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AppService } from '@core/services/app.service';
import { takeWhile, tap } from 'rxjs/operators';
import { BaseComponent } from './shared/components/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends BaseComponent implements OnInit {
  pageReady = false;
  buildVersion = '259454d7f';

  constructor(private injector: Injector, private appService: AppService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.translateDatepicker();
    this.createAPICache();
  }

  private translateDatepicker() {
    this.languageService
      .translateDatepicker()
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  private createAPICache() {
    this.appService
      .createAPICache(this.buildVersion)
      .pipe(
        takeWhile(() => this.alive),
        tap(() => this.renderApp()),
        tap(() => (this.cache.buildVersion = this.buildVersion))
      )
      .subscribe();
  }

  private renderApp() {
    this.loadingService.stopLoading();
    this.pageReady = true;
    this.cd();
  }
}
