<app-progress-spinner [color]="'accent'" [mode]="'indeterminate'" [value]="50" [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="isLoading$ | async">
</app-progress-spinner>
<mat-drawer-container class="drawer-container" id="secureHomeComponent">
    <mat-drawer-content>
        <mat-toolbar class="mat-elevation-z3">
            <mat-toolbar-row>
                <h2>{{ "home.auth.text.myWork" | translate | async }}</h2>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="flex flex--cols-2 flex--full-height" id="searchRow">
            <div class="flex-child flex-child--flex-grow flex flex--cols-2">
                <div class="flex-child">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label attr.aria-label="{{ 'home.auth.text.search' | translate | async }}">
                            {{ "home.auth.text.search" | translate | async }}
                        </mat-label>
                        <input matInput maxlength="30" (keyup)="search($event.target.value)" placeholder="" attr.aria-label="{{ 'home.auth.text.search.aria.text' | translate | async }}" />
                        <mat-icon matTextSuffix attr.aria-label="{{ 'home.auth.text.search.aria.text' | translate | async }}" aria-hidden="true"> search </mat-icon>
                    </mat-form-field>
                </div>
                <app-label-filter #labelFilter *ngIf="loaded" [worksheets]="worksheets" (onUpdate)="updateLabel($event)"></app-label-filter>
            </div>
            <div class="flex-child flex-child--flex-shrink flex flex--cols-2 flex--end-vertical">
                <div class="flex-child flex-child--flex-shrink">
                    <button type="button" mat-stroked-button class="button button--green-primary" (click)="import()">
                        {{ "home.auth.text.import.plan" | translate | async }}
                    </button>
                </div>
                <div class="flex-child flex-child--flex-shrink">
                    <button type="button" mat-stroked-button class="button button--green-primary" (click)="create()">
                        {{ "home.content.create.plan" | translate | async }}
                    </button>
                </div>
            </div>
        </div>
        <div class="padding-vertical--1rem" [style.display]="dataSource?.data?.length > 0 ? 'block' : 'none'">
            <table #worksheetTable mat-table [trackBy]="trackByFn" [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "name" | translate | async }}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="navigateTo(element)">
                        {{ element.name }}
                        <span class="label" *ngFor="let label of element.labels" matTooltip="{{ 'home.auth.text.tooltip.label' | translate | async }}" matTooltipPosition="below">
                            {{ label.labelName }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="worksheetTypeId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "type" | translate | async }}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="navigateTo(element)">
                        {{ worksheetType(element.worksheetTypeId) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="updatedDateTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "home.auth.text.dateTimeModified" | translate | async }}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="navigateTo(element)">
                        {{ element.updatedDateTime | date : "mediumDate" : "UTC" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <span style="display: none">
                            {{ "ellipsis.button.text" | translate | async }}
                        </span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <button
                            type="button"
                            mat-icon-button
                            #menuTrigger="matMenuTrigger"
                            [matMenuTriggerFor]="actionMenu"
                            [matMenuTriggerData]="{ row: row }"
                            class="action-button float--right"
                            (click)="$event.stopPropagation()"
                            matTooltip="Options"
                            matTooltipPosition="left">
                            <mat-icon aria-hidden="false" attr.aria-label="Options"> more_horiz </mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns" tabindex="0" (keydown.enter)="navigateTo(row)"></tr>
            </table>
        </div>
        <div id="authHomeComponentNoPlanMessage" class="font-weight--medium" *ngIf="dataSource?.data?.length <= 0">
            <p>
                {{ "home.auth.no.plan.message" | translate | async }}
            </p>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
<mat-menu #actionMenu>
    <ng-template matMenuContent let-row="row">
        <button type="button" mat-menu-item class="menu-action-btn" (click)="rename(row)">
            {{ "home.auth.text.rename" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canCopy(row.worksheetTypeId)" (click)="copy(row)">
            {{ "home.auth.text.copy" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canExport(row.worksheetTypeId)" (click)="export(row)">
            {{ "home.auth.text.export" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" (click)="openLabelDialog(row)">
            {{ "home.auth.text.apply.label" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canCreateNmsp(row.worksheetTypeId)" (click)="convert(row, 'NUTRIENT_MANAGEMENT_STRATEGY_PLAN')">
            {{ "calculator.create.nmsp" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canCreateGhg(row.worksheetTypeId)" (click)="convert(row, 'GREENHOUSE_GAS')">
            {{ "calculator.create.ghg" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" (click)="delete(row.id)">
            <span class="colour--red-primary">
                {{ "home.auth.text.delete" | translate | async }}
            </span>
        </button>
    </ng-template>
</mat-menu>
